import {usePage} from "@inertiajs/vue3";

export function __(key, replace = {}): string {
    const page = usePage();
    let translations = page.props?.translations || {};
    let translation = translations[key] ? translations[key] : key;

    Object.keys(replace).forEach(function (key) {
        translation = translation.replace(':' + key, replace[key])
    });

    return <string>translation;
}
